import { Box, Link } from '@chakra-ui/layout';
import Heading from '@components/Heading';
import { EditorContextHelper } from '@magnolia/react-editor';
import { getColor } from '@utils/getColor';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useState } from 'react';
import { HeadingElements, HeadingSizes } from '~/@types/heading';
import { MagnoliaImage, Metadata } from '~/types/Magnolia';
const DAMImage = dynamic(() => import('@components/Image/DAMImage'));
const FullWidthContainer = dynamic(
  () => import('@components/LayoutStructure/Container')
);

interface BannerDividerProps {
  element: HeadingElements | 'p';
  headingSize: HeadingSizes;
  title: string;
  floating: boolean;
  switchable: SwitchableProps;
  backgroundColor: string;
  backgroundColorRange: string;
  metadata: Metadata;
  hideLinkPointingToOwnPage?: boolean;
  moveTitleToTop?: boolean;
  titleColor?: string;
  titleColorRange?: string;
  addShadowBox?: boolean;
}

export interface SwitchableProps {
  images: {
    '@nodes': string[];
    images0?: { image: MagnoliaImage };
    images1?: { image: MagnoliaImage };
    images2?: { image: MagnoliaImage };
    images3?: { image: MagnoliaImage };
    images4?: { image: MagnoliaImage };
  };
  links: {
    '@nodes': string[];
    links0?: { linkHref: string; linkText: string };
    links1?: { linkHref: string; linkText: string };
    links2?: { linkHref: string; linkText: string };
    links3?: { linkHref: string; linkText: string };
    links4?: { linkHref: string; linkText: string };
  };
  field: 'images' | 'links';
}

const BannerDivider = (props: BannerDividerProps): JSX.Element => {
  const [inEditor, setInEditor] = useState(false);
  const router = useRouter();
  const pagePath = router?.asPath ?? '';
  const {
    element,
    headingSize = 'md',
    title,
    floating = 'false',
    switchable,
    backgroundColor = 'secondary',
    backgroundColorRange = '200',
    metadata,
    hideLinkPointingToOwnPage = true,
    moveTitleToTop = false,
    titleColor,
    titleColorRange,
    addShadowBox = false,
  } = props;

  const { field } = switchable;
  const bgColor = getColor(backgroundColor, backgroundColorRange);
  const textTitleColor = getColor(titleColor, titleColorRange);

  useEffect(() => {
    EditorContextHelper.inEditorAsync().then((inEditor) => {
      setInEditor(inEditor);
    });
  }, []);

  const verifyLink = (link: string) => {
    if (hideLinkPointingToOwnPage) {
      // router.asPath returns the path with a trailing slash in the client but not in the server, so we need to remove it to compare
      const pagePathWithoutTrailingSlash = pagePath.replace(/\/$/, '');
      const linkWithoutTrailingSlash = link.replace(/\/$/, '');
      return pagePathWithoutTrailingSlash !== linkWithoutTrailingSlash;
    }
    return true;
  };

  return (
    <FullWidthContainer
      m="auto"
      position="relative"
      py={floating ? 0 : 4}
      px={0}
    >
      <Box
        p={floating ? 0 : 8}
        flexDirection={
          moveTitleToTop ? 'column' : { md: 'row', base: 'column' }
        }
        justifyContent="center"
        textAlign={floating ? 'left' : { md: 'left', base: 'center' }}
        alignItems="center"
        display={floating ? { md: 'flex', base: 'none' } : 'flex'}
        gridGap={moveTitleToTop ? { base: 6, md: '14px' } : { base: 8, md: 10 }}
        position={inEditor ? 'relative' : floating ? 'absolute' : 'relative'}
        backgroundColor={bgColor}
        left={0}
        right={0}
        top={inEditor ? 0 : floating ? -49 : 0}
        height={floating ? 98 : { md: 112, base: 'auto' }}
        borderRadius={12}
        boxShadow={
          floating || addShadowBox
            ? '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)'
            : ''
        }
        zIndex={1000}
      >
        {element !== 'p' ? (
          <Heading
            headingElement={element}
            headingSize={headingSize}
            maxWidth={210}
            title={title}
            withContainer={false}
            color={textTitleColor}
          />
        ) : (
          <Box
            className="magnolia-text"
            textStyle="paragraph"
            maxWidth={210}
            fontWeight={600}
            color={textTitleColor}
          >
            {title}
          </Box>
        )}
        <Box
          display="flex"
          flexDirection={{ md: 'row', base: 'column' }}
          justifyContent="center"
          textAlign={floating ? 'left' : { md: 'left', base: 'center' }}
          alignItems="center"
          gridGap={{ base: 8, md: 10 }}
        >
          {switchable[field] &&
            switchable[field]['@nodes'].map((key, index) => {
              return field === 'links' ? (
                <Fragment key={metadata['@id'] + index}>
                  {verifyLink(switchable[field][key].linkHref) && (
                    <Link
                      href={switchable[field][key].linkHref}
                      textDecorationLine="underline"
                    >
                      {switchable[field][key].linkText}
                    </Link>
                  )}
                </Fragment>
              ) : (
                <DAMImage
                  key={metadata['@id'] + index}
                  src={switchable[field][key].image}
                />
              );
            })}
        </Box>
      </Box>
    </FullWidthContainer>
  );
};

export default BannerDivider;
